const getImageSrc = (image: string) => {
  try {
    if (!image.includes("http")) return require("@/assets/" + image);
    else return image;
  } catch {
    return image;
  }
};

export default getImageSrc;
